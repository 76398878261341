.button {
  color: black;
  display: inline-block;
  font-weight: 500;
  font-size: larger;
  cursor: pointer;
  padding: 0.4em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  margin-right: 1em;
  border-radius: 1em;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgb(0, 0, 0);
}

.h4-extra {
  margin-bottom: 2vh;
  font-size: 1.8rem;
}

.list-container {
  margin-bottom: 4vh;
}

.list-style {
  list-style: inside;
  padding-left: 0.5vw;
}