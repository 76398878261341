.plane-3d-1 {
  z-index: -2;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  background-image: url(../res/assets/Background/Path_78.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.plane-3d-2 {
  z-index: -2;
  position: absolute;
  right: 0;
  bottom: -5px;
  width: 50%;
  height: 40%;
  background-image: url(../res/assets/Background/Path_79.svg);
}
div{
  border:0px solid black;
}

.bg-quote {
  position: absolute;
  top: 5%;
  left: 13%;
  width: 30%;
  height: 30%;
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-molecule-1 {
  z-index: -1;
  position: absolute;
  opacity: 0.08;
  bottom: -13%;
  right: -32%;
  width: 85%;
  height: 85%;

  img {
    width: 100%;
    height: 100%;
  }
}

.bg-molecule-2 {
  z-index: -1;
  position: absolute;
  bottom: -20%;
  right: -22%;
  width: 50%;
  height: 60%;
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-core-molecule {
  z-index: 0;
  position: absolute;
  top: -5%;
  right: 10%;
  width: 40%;
  height: 45%;
  background-image: url(../res/assets/Background/coreMolecule.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-core-polygon {
  z-index: 0;
  position: absolute;
  bottom: -5%;
  right: -60%;
  width: 100%;
  height: 100%;
  background-image: url(../res/assets/Background/corePolygon.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-platform-molecule {
  z-index: -1;
  position: absolute;
  bottom: -20%;
  left: -2%;
  width: 25%;
  height: 25%;
  background-size: contain;
  background-repeat: no-repeat;
}

.homePageHeadline {
  float: left;
  color: white;

  width: 85%;
  text-align: left;
}

// .homePageHeadlineTitle {
//   line-height: 1em;
//   font-size: 3.5em;
// }

.homePageHeadlineDesc {
  font-size: 1.5em;
  width: 100%;
  line-height: 1.2em;
  /* font-family:RubikRegular; */
}

.responsiveGridCore {
  display: grid;
  grid-gap: 2em;
  width: 85%;
  margin: auto;
}

.feature {
  display: flex;
}

.platforms {
  padding-left: 5%;
  padding-right: 5%;
  display: grid;
  grid-template-columns: auto auto;
}

@media(min-width: 1151px) {
  .homePageHeadline {
    padding-left: 100px;
    padding-top: 120px;
  }

  .platformHex {
    max-width: 100% !important;
  }
}

@media(max-width: 1150px) {
  .homePageHeadline {
    width: 90% !important;
    padding-left: 10%;
  }

  .platformHex {
    max-width: 40% !important;
  }
}

@media(max-width: 502px) {
  .responsiveGridCore {
    grid-template-columns: 1fr;
  }

  .howItWorksFeatures {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .feature {
      justify-content: center;
      align-content: center;

      .featureDesc {
        display: none;
      }
    }
  }

  .platformHex {
    max-width: 100% !important;
  }
}

@media(min-width: 502px) and (max-width: 1150px) {
  .platformHex {
    max-width: 80% !important;
  }

  .responsiveGridTablet {
    max-width: 55%;
    // gap: 1em;
    // margin: 0 auto;
    // margin-right: 12%;
   
    grid-template-columns: 1fr;
  
  }
}