@import "colors";

.platform-novo-bg {
  position: absolute;
  top: -15%;
  left: 0;
  width: 140%;
  height: 140%;
  background-image: url(../res/assets/Background/DeNovoBG.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.platform-bio-bg {
  position: absolute;
  top: -5%;
  left: -10%;
  width: 100%;
  height: 100%;
  background-image: url(../res/assets/Background/BioactivityBG.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.benefits {
  li {
    color: white; padding-left: 15px; padding-bottom: 1.2em;
  }
}

.subList {
  font-size: 1em;
  line-height: 1.2em;
  padding-left: 20px;
}

.subSubList {
  font-size: 1em;
  line-height: 1.2em;
  padding-left: 25px;
}

.description {
  p {
    font-size: 1em;
    line-height: 1.2em;
  }
}

.platform-admet-bg {
  position: absolute;
  top: -15%;
  left: -2%;
  width: 110%;
  height: 110%;
  background-image: url(../res/assets/Background/ADMET_BG.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.platform-clinical-bg {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 100%;
  height: 100%;
  background-image: url(../res/assets/Background/ClinicalBG.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.platformDesc {
  padding-top: .7em;
  width: 120%;
}

// .slick-prev {
//   left: 23% !important;
//   z-index: 1;
// }

// .slick-next {
//   right: 23% !important;
//   z-index: 1;
// }

#macbookSlickDots {
  bottom: -30% !important;
}

.slick-next {
  right: -8% !important;
}
.slick-prev {
  left: -8% !important;
}

.slick-prev::before, .slick-next::before {
  color: $blue-bright !important;
}

@media(max-width: 1150px) {
  .platformDesc {
    width: 100%;
  }

  #macbookSlickDots {
    bottom: -40% !important;
  }
  
  .slick-next {
    right: -16% !important;
  }
  .slick-prev {
    left: -16% !important;
  }
}