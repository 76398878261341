@import "colors";

.covid-images {
  // width: 95%;
  // margin: auto;
  gap: 4em;

  div {
    .covid-image {
      max-width: 100%;
    }

    .covid-image-desc {
      font-size: 0.9em;
      padding-top: 1em;
      color: $dark;
      opacity: 0.6;
    }
  }
}

.newsMasterPage {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: baseline;
}

.newsWidget {
  max-width: 550px;
  min-height: 630px;
  min-width: 550px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

.newsWidgetDate{
  position: absolute;
  width: 490px;
  bottom: 0;
  padding-bottom: 45px;
}

.nelfinavirText{
  width: 70%;
}

@media (max-width: 550px){
  .newsWidget {
    max-width: 350px;
    min-height: auto;
    min-width: 350px;
    padding-top: 20px;
    padding-bottom: 80px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
  }
  .newsWidgetDate{
    position: absolute;
    width: 290px;
    bottom: 0;
    padding-bottom: 45px;
  }
}

.newsWidgetPhoto {
  width: 20vw;
  height: 20vw;
  min-width: 235px;
  min-height: 235px;
  max-width: 250px;
  max-height: 250px;
}


// This wide photo css is now only used in one picture, later on if we need more wide pictures, more fiddling with picture height - width ratio is needed
.newsWidgetPhotoWide {
  width: 20vw;
  height: 20vw;
  min-width: 480px;
  min-height: 220px;
  max-width: 480px;
  max-height: 220px;
}

.newsWidgetPhotoUnstretched {
  width: 80%;
}

.calendarIcon {
  max-height: 20px;
  max-width: 20px;
}

.newMemberPage {
  padding-left: 5vw;
  padding-right: 5vw;
}

@media (min-width: 861px) {
  .newMemberPageTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .eventPageTop {
    display: flex;
    justify-content: space-between;
  }

  .newMemberArticle {
    width: 50%;
  }
  .newMemberMobilePagePhoto {
    display: none;
  }
  .eventMobilePhoto {
    display: none;
  }
  .mobileEventImageCaption{
    display: none;
  }
  .eventMobileBottomPictures{
    display: none;
  }
}

.newMemberPagePhoto {
  min-width: 300px;
  max-width: 20vw;
  margin-left: 12vw;
  margin-right: 5vw;
}

.eventPhoto {
  min-width: 650px;
  max-width: 20vw;
  margin-left: 2vw;
  margin-right: 1.5vw;
  margin-bottom: 0.1vh;
}

.eventImageCaption{
  margin-left: 2vw;
  width: 650px;
  margin-right: 0;
  margin-bottom: 2vh;
  font-size: 1rem;
  font-style: italic;
  color: gray;
}

.articleText {
  margin-top: 4vh;
  padding-left: 10vw;
  padding-right: 10vw;
}

.eventImageList{
  display: flex;
  flex-direction: column;
}

@media (min-width: 860px) and (max-width: 1050px){
  .eventPhoto {
    min-width: 400px;
    max-width: 20vw;
    margin-left: 2vw;
    margin-right: 1.5vw;
    margin-bottom: 0.1vh;
  }
  .eventImageCaption{
    width: 400px;
  }
}

@media (min-width: 1051px) and (max-width: 1350px){
  .eventPhoto {
    min-width: 500px;
    max-width: 20vw;
    margin-left: 2vw;
    margin-right: 1.5vw;
    margin-bottom: 0.1vh;
  }
  .eventImageCaption{
    width: 500px;
  }
}

@media (max-width: 860px) {
  .newMemberPagePhoto {
    display: none;
  }
  
  .eventPhoto {
    display: none;
  }

  .eventImageCaption{
    display: none;
  }

  .newMemberArticle {
    width: 100%;
  }

  .newMemberMobilePagePhoto {
    min-width: 300px;
    max-width: 20vw;
    margin-bottom: 5vh;
  }
  
  .eventMobilePhoto {
    min-width: 450px;
    max-width: 20vw;
    margin-bottom: 0.1vh;
  }
  
  .mobileEventImageCaption{
    margin-bottom: 5vh;
    font-size: 1rem;
    font-style: italic;
    color: gray;
  }

  .nelfinavirText{
    width: 90%;
  }

  .nelfinavirPhotoDesktop{
    display: none;
  }

  .newsWidgetPhotoWide {
    width: 20vw;
    height: 20vw;
    min-width: 290px;
    min-height: 133px;
    max-width: 290px;
    max-height: 133px;
  }
}

@media (max-width: 520px){
  .eventMobilePhoto {
    min-width: 290px;
    max-width: 20vw;
    margin-bottom: 0.1vh;
  }
}

.story{
  width: 60%;
  min-width: 250px;
  padding-top: 0.3em;
  margin: auto;
  font-size: 27px;
  font-family: RubikRegular;
}