@import "colors";

.formBox {
  border: none;
  background-color: $contact-form-bg;
  padding: .5em;
  margin-bottom: 1.5em;
  border-radius: 2px;

}

.custom-select {
  position: relative;
  border: none;
  font-size: 16px;
  // color:grey;
  // overflow:hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
  height:.8em !important;
  color:#757575;
  border-radius: 2px;
  font-size:inherit;
  padding: .2em;
  padding-left:.5em;
  float: right;
  background-color: $contact-form-bg;
  /* max-width:100%; */
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}


.select-selected {
  background-color: #e8eff7;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #000 transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #000 transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  // border: 1px solid transparent;
  // border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

.select-items div {
  padding-left: 10px !important;
}

// .select-selected {
//   border: 1px solid transparent;
//   border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
// }

/*style items (options):*/
.select-items {
  color: white;
  position: absolute;
  background-color: #61a7e3;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.contactInput {
  width: 45%;
  margin: auto;
}
@media(max-width:750px){
  .contactDiv{
    flex-direction: column;
  }
  .contactInput{
    width:95%;
  }
  .generalEnquiries{
    display:none;
  }
  .contact-general-mobile{
    margin-bottom: 3vh;
  }
}
@media(min-width:751px){
  .contact-general-mobile{
    display: none;
  }
}

.contactUsHeader {
  position: relative;
  font-size: 3em;
  margin-bottom: 3em;
  color: #444;
  background-image: $gradient6-45deg-rev;
  color: white;
  font-family: RubikMedium;

  .contact-header-content {
    padding-top: 3em;
    padding-bottom: 1.2em;
    font-size: 1.2em;
    line-height: 1em;
  }

  .bg-rounded-polygon {
    position: absolute;
    bottom: -15%;
    left: 5%;
    width: 120%;
    height: 120%;
    background-image: url(../res/assets/Background/contact/RoundedPolygon.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bg-polygon-outline {
    position: absolute;
    top: -55%;
    right: -110%;
    width: 150%;
    height: 150%;
    background-image: url(../res/assets/Background/contact/PolygonOutline.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.contact-general {
  position: relative;
  flex: 1;
  padding-right:50px;

  .bg-molecule {
    z-index: -2;
    position: absolute;
    bottom: -120%;
    left: -95%;
    width: 200%;
    height: 200%;
    background-image: url(../res/assets/Background/contact/Molecule.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
  }
}

@media(max-width: 1150px) {
  .formLeft,.formRight {
    width: 100%;
  }
}

@media(min-width: 1150px) {
  .formLeft {
    width: 47%;
    float: left;
  }

  .formRight {
    width: 47%;
    float: right;
  }
}