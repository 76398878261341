.historyHeader {
  .bg-molecule {
    position: absolute;
    bottom: -15%;
    left: 11%;
    width: 100%;
    height: 100%;
    background-image: url(../res/assets/Background/history/molecule.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bg-rounded-polygon {
    position: absolute;
    top: -60%;
    left: 21%;
    width: 100%;
    height: 150%;
    background-image: url(../res/assets/Background/history/RoundedPolygon.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bg-polygon-outline {
    position: absolute;
    top: -40%;
    right: -20%;
    width: 50%;
    height: 120%;
    background-image: url(../res/assets/Background/history/PolygonOutline.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}