.navBar {
  margin-bottom: 4em;
  /* height:6.2em; */
  display: flex;
  justify-content: flex-end;
  grid-template-columns: auto auto auto auto auto;
  /* width:70%; */
  min-width: 300px;
}

.clickableHeader:hover {
  // filter: brightness(80%);
}

.dropdown {
  margin-left: -2em;
  // width: 8em;

  margin-top: 0.4vh;
  padding-bottom: 10px;
  padding-right: 20px;
  text-align: left;

  /* padding-left:1em; */
}

.dropdown:hover {
  display: relative;
  height: 100%;
  top: 0;
  background-color: #0564a8;
  border-radius: 10px;
  /* width:100%; */
}

.dropdownContainer {
  margin-left: .5em;
  position: absolute;
}

.headerText {
  color: #fff;
  // font-size: .8em;
  font-size:15px;
  font-family:RubikRegular;


}
@media(max-width:1050px){
  .headerText{
  font-size:13px;
  }
}
@media(max-width:930px){
  .headerText{
  font-size:12px;
  }
}

.headerText {
  border: 1px;
}

.headerText {
  /* padding-right:2em; */

  padding-top: 2em;
  margin-left: 1.5em;
  /* max-width:50%; */
  /* width:70%; */
  height: 2em;

  /* border:3px solid pink; */
  display: inline-block;
  
}

.headerText:hover .dropdown {
  display: relative;
  height: initial;
  top: 0;
  /* width:100%; */
  z-index: 100;
}

.headerText:hover {
  cursor: pointer;
  
}

.headerTextContainer:hover .headerText .dropdown {
  z-index: 9999;
  /* backdrop-filter:brightness(20%) */
  /* background-color:#fff; */
  /* -webkit-box-shadow:10px 12px 40px -5px rgba(0,0,0,0.3);
  -moz-box-shadow:10px 12px 40px -5px rgba(0,0,0,0.3);
  box-shadow:10px 12px 40px -5px rgba(0,0,0,0.3); */
  // width: 200%;
  padding-left: 1em;
  overflow: visible;
  background-color: #0564a8;
  border-radius: 10px;
  font-weight: 200;
}

.selectedHeaderText, .headerText:hover, .headerText:active {
  padding-bottom: 15px;
  transition: all fade-in 10s;
  border-width: thin;
  font-weight: 1000;
}

.underline:hover, .underline:active {
  text-decoration: underline;
}

.dropdown:first-child {
  padding-top: 5px;
}

.headerText:hover .dropdown .dropdownElem:hover {
  opacity: .4;
  display: block;
  position: relative;
  z-index: 1000;
  color: rgb(200, 200, 200) !important;
}

.selectedDropdownElem {
  color: rgb(200, 200, 200) !important;
}

.dropdownElem:hover {
  opacity: 1 !important;
}

.headerText .dropdown .dropdownElem {
  /* visibility:visible; */
  /* opacity:.4; */
  /* display:block; */
  position: relative;
  z-index: 1000;
  color: rgb(255, 255, 255);
  line-height: 1em;
}

.headerTextContainer:hover .headerText .dropdown .dropdownElem {
  visibility: visible;
  display: block;
}

.dropdownElem {
  /* display:block; */
  display: none;
  /* visibility:hidden; */
  padding-top: .3em;
  padding-bottom: .3em;
  padding-left: .5em;
  text-transform: capitalize !important;
  /* border:2px solid blue; */
}

// .desktop{
//   width: 65vw;
//   display: flex;
//   justify-content: flex-end;
// }

.unclickableDropdown{
  cursor: default;
}