@media (max-width: 502px) {
  .footerRight, .footerMid {
    display: none !important;
  }

  .footerLeft {
    max-width: 100% !important;
  }

  .footerRightMobile {
    display: block !important;
    width: 100%;
  }
}

.footer-container {
  display: flex;
  width: 100vw;
  justify-content: flex-start;
  // gap: 15vw;
  padding-bottom: 25px;

  .footerLeft {
    max-width: 45%;

    img {
      max-width: 90%;
    }

    .copyright {
      color: white;
      margin-bottom: 0;
    }
  }

  .footerRightMobile {
    display: none;
  }

  .footerRight .officeHeader, .footerRightMobile .officeHeader {
    font-family: RubikMedium;
  }

  .footerRight span, .footerRightMobile span {
    display: block;
  }

  .footerLinks {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: .9em;
  }

  .footerLinkNoPointer {
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .footerLinkDropdownUndr {
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
  }

  .footerLinkNoPointer:hover {
    // text-decoration: underline;
  }

  .footerLinkDropdownUndr:hover {
    text-decoration: underline;
  }

  .footerLink.indent {
    padding-left: 1.3em;
  }
}

.footer-top{
}

.caretFooterTransform {
  transform: rotate(-180deg);
  /* Equal to rotateZ(45deg) */
  transition: transform .5s linear;
  padding-top: 2px;
  padding-bottom: initial;
  margin-left: 0.5vw; 
}

@media(min-width: 1151px) {
  .ro5LogoFooter{
    width: 30%;
    max-width: 300px;
  }
}

.caretFooter {
  vertical-align: middle;
  padding-bottom: 2px;
  transition: transform .5s linear;
  margin-left: 0.5vw;  
}