.fade-in {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* A weird set of keyframes that can do anything */
@keyframes aW_sH {
  from {
    height: 100%;
    width: 0%;
  }

  to {
    height: 100%;
    width: 100%;
  }
}

@keyframes sW_aH {
  from {
    width: 100%;
    height: 0%;
  }

  to {
    width: 100%;
    height: 100%;
  }
}

@keyframes sW_sH {
  from {
    width: 100%;
    height: 100%;
  }

  to {
    width: 100%;
    height: 100%;
  }
}

@keyframes nW_nH {

  from,
  to {
    border-width: 0px;
  }
}

@keyframes nW_aH {
  from {
    height: 0%;
  }

  to {
    height: 100%;
  }
}

@keyframes nW_sH {

  from,
  to {
    height: 100%;
  }
}

@keyframes sW_nH {

  from,
  to {
    width: 100%;
  }
}

@keyframes aW_nH {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}