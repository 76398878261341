// Static colors
$white: #FFFFFF;
$dark: #434343;
$blue: #0050A9;
$blue-bright: #12AFE1;
$blue-alt: #61A7E3;
$blue-muted: #4681B4;
$news-bg: #37B7E5;
$contact-form-bg: #0050A910;

// Gradients
$gradient1: linear-gradient($blue-bright, $blue);
$gradient1-90deg-rev: linear-gradient(90deg, $blue, $blue-bright);
$gradient2: linear-gradient($blue-alt, $blue);
$gradient2-45deg: linear-gradient(45deg, $blue-alt, $blue);
$gradient2-45deg-rev: linear-gradient(45deg, $blue, $blue-alt);
$gradient2-110deg: linear-gradient(110deg, $blue-alt, $blue);
$gradient2-110deg-rev: linear-gradient(110deg, $blue, $blue-alt);
$gradient2-135deg: linear-gradient(135deg, $blue-alt, $blue);
$gradient3: linear-gradient(#A8D9F5, #2A73B2);
$gradient3-45deg: linear-gradient(45deg, #A8D9F5, #2A73B2);
$gradient4: linear-gradient($blue, #3CD3D7);
$gradient4-45deg-rev: linear-gradient(45deg, #3CD3D7, $blue);
$gradient5: linear-gradient(#4483B5, #15ACDE);
$gradient6: linear-gradient($blue-muted, $blue-alt);
$gradient6-45deg: linear-gradient(45deg, $blue-muted, $blue-alt);
$gradient6-45deg-rev: linear-gradient(45deg, $blue-alt, $blue-muted);
$footer-gradient: linear-gradient(#ADC0D1, #91ACC4);