.meet-team-container {
  /* background-image:url(./res/canyonStars.jpg); */
  /* min-height:100%; */
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-position:static; */
  background-size: cover;
  /* filter: brightness(40%) ; */
  /* position:static; */
}

.meet-team-scroll {
  position: fixed;
}

.responsiveGridTeam {
  display: grid;
  grid-gap: 2em;
}

.meetTeamGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  height: 100%;
  /* grid-gap:1em; */

  grid-gap: 1em;
  padding-top: 1.3em;

  margin: auto;
}

.meetTeamGridLeadership {
  display: grid;
  grid-template-columns: repeat(4, minmax(157px, 1fr));
  height: 100%;
  /* grid-gap:1em; */
  grid-gap: 3em;
  row-gap: 2em;
  padding-top: 1.3em;
  padding-bottom: 4em;
  margin: auto;
}
.smallTeamGrid{
  grid-gap: .1em;
  row-gap: 2em;
}

.meet-team-header {
  font-size: 3em;

  color: #444;
  padding-top: 160px;
  padding-bottom: 50px;
  margin-bottom: 3em;
  background-image: linear-gradient(110deg, #12AFE1, #4681B4);
  color: white;
  font-family: RubikMedium;
}

.team-member-card {
  height: 100%;
  position: static;
}

.team-member-image:hover,
.team-member-image:active {
  cursor: pointer;
  filter: brightness(80%);
}

.team-member-name {
  text-align: center;
  font-size: 1.9rem;
}

.advisory-member-name {
  text-align: center;
  font-size: 1.7em;
}

//.advisoryBoardWrapper {
//  background-image: linear-gradient(110deg, #12AFE1, #4681B4);
//  color: #444;
//  padding-bottom: 25px;
//}
@media(max-width:500px){
  .team-member-name {
    font-size:1.6em;
  }
}
.team-member-headline {
  font-size: 1.5rem;
  padding-top: .5em;
}

// /* .team-member-image-holder::after {
//   z-index:100;
//   position:absolute;
//   content:'+';
//   font-size:2em;
//   font-family:RubikBold;
//   color:white;
//   padding-left:30px;
//   padding-right:30px;
//   padding-top:15px;
//   padding-bottom:15px;
//   margin-top:-5%;;
//   margin-left:12%;
//   background-image:linear-gradient(225deg,#61A7E3,#0E5DB2);
//   border-radius:100%;
//   display:block;
// } */

.meet-team-header {
  position: relative;

  .bg-polygon-outline {
    position: absolute;
    bottom: -15%;
    left: -8%;
    width: 100%;
    height: 100%;
    background-image: url(../res/assets/Background/team/PolygonOutline.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bg-molecule {
    position: absolute;
    top: 8%;
    left: 32%;
    width: 100%;
    height: 100%;
    background-image: url(../res/assets/Background/team/Molecule.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bg-rounded-polygon {
    position: absolute;
    top: -25%;
    right: 0;
    width: 25%;
    height: 100%;
    background-image: url(../res/assets/Background/team/RoundedPolygon.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

//.advisoryBoardWrapper {
//  position: relative;
//
//  .bg-polygon-outline {
//    position: absolute;
//    bottom: -43%;
//    left: 31%;
//    width: 100%;
//    height: 100%;
//    background-image: url(../res/assets/Background/team/PolygonOutline.svg);
//    //background-size: contain;
//    background-repeat: no-repeat;
//  }
//
//  .bg-polygon-outline-second {
//    position: absolute;
//    bottom: -15%;
//    left: -8%;
//    width: 100%;
//    height: 100%;
//    background-image: url(../res/assets/Background/team/PolygonOutline.svg);
//    //background-size: contain;
//    background-repeat: no-repeat;
//  }
//
//  .bg-molecule-second {
//    position: absolute;
//    left: 5%;
//    bottom: -81%;
//    height: 100%;
//    background-image: url(../res/assets/Background/team/Molecule.svg);
//    //background-size: contain;
//    background-repeat: no-repeat;
//  }
//
//  .bg-molecule {
//    position: absolute;
//    top: 8%;
//    left: 72%;
//    width: 100%;
//    height: 100%;
//    background-image: url(../res/assets/Background/team/Molecule.svg);
//    //background-size: contain;
//    background-repeat: no-repeat;
//  }
//
//  .bg-rounded-polygon-second {
//    position: absolute;
//    top: 65%;
//    right: -11%;
//    width: 25%;
//    height: 100%;
//    background-image: url(../res/assets/Background/team/RoundedPolygon.svg);
//    //background-size: contain;
//    background-repeat: no-repeat;
//  }
//
//  .bg-rounded-polygon {
//    position: absolute;
//    top: -14%;
//    right: 46%;
//    width: 25%;
//    height: 100%;
//    background-image: url(../res/assets/Background/team/RoundedPolygon.svg);
//    //background-size: contain;
//    background-repeat: no-repeat;
//  }
//}

@media(min-width: 801px) and (max-width:1200px) {
  .team-member-image {
    width: 157px;
    height: 157px;
  }

  .centerOne {
    grid-template-columns: repeat( 4, 1fr );
    div {
      grid-column: span 2;

      grid-column: 2 / span 2;
    }
  }
  .centerLast {
    grid-template-columns: repeat( 4, 1fr );
    div {
      grid-column: span 2;
    }
    // UNCOMMENT THIS IF THERE IS ODD NUBER OF MEMBERS IN ADVISORY BOARD
    // div:last-child {
    //   grid-column: 2 / span 2;
    // }
  }

  .advisoryBoard {
    padding: .5em 0;
  }

  .gridFor3Items {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2em;

    div {
      //grid-column: span 2;

      &:last-child:nth-child(3n - 1) {
        grid-column-end: -2;
      }

      &:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }
    }
  }
  .meetTeamGrid {
    width: 90%;
  }

  .team-member-name {
    padding-top: .4em;
    font-size:2rem;
  }

  .team-member-headline {
    // width: 85%;
    // margin: auto;

    font-size:1.5rem;
  }

  .meetTeamGridLeadership {
    width: 90%
  }

  .team-member-card {
    width: 190px;
  }

  .responsiveGridTeam {
    width: 90%;
    margin: auto;
  }
  .responsiveGridPrincipalScientists {
    width: 90%;
    margin: auto;
  }


}
@media(min-width:1201px){
  .team-member-image {
    width: 220px;
    height: 220px;
  }

  .centerLast {
    grid-template-columns: repeat( 4, 1fr );
    div {
      grid-column: span 2;
    }
    // UNCOMMENT THIS IF THERE IS ODD NUBER OF MEMBERS IN ADVISORY BOARD
    // div:last-child {
    //   grid-column: 2 / span 2;
    // }
  }

  .centerOne {
    grid-template-columns: repeat( 4, 1fr );
    div {
      grid-column: span 2;

      grid-column: 2 / span 2;
    }
  }

  .advisoryBoard {
    grid-gap: 0em!important;
    padding-top: .5em;
  }
  .gridFor3Items {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3em;
    row-gap: 2em;

    div {
      //grid-column: span 2;

      &:last-child:nth-child(3n - 1) {
        grid-column-end: -2;
      }

      &:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }
    }
  }
  .gridFor4Items {
    div {
      &:last-child:nth-child(4n - 2) {
        grid-column-start: 3
      }

      &:nth-last-child(2):nth-child(4n + 1) {
        grid-column-start: 2
      }
    }
  }
.team-member-name{
  padding-top: .4em;
}
.team-member-headline{
  padding-top: .4em;
}
  .meetTeamGridLeadership {
    width: 80%
  }

  .team-member-card {
    width: 97%;
    max-width: 97%;
  }

  .responsiveGridTeam {
    width: 70%;
    margin: auto;
  }
  .responsiveGridPrincipalScientists {
    width: 80%;
    margin: auto;
  }

  .responsiveGridDev {
    width: 50%;
    margin: 0 auto;
  }
}
.teamMemberRow{
  width:85%;
}

@media(max-width: 800px) {
  .team-member-image {
    width: 150px;
    height: 150px;
    margin-right: 2vw;
  }
  .advisoryBoard {
    padding-top: 25px;
  }

  .advisory-roles{
    text-align: left;
  }

  .teamMemberRow {
    width: 95%;
  }

  .team-member-card, .advisory-member-card {
    display: grid;
    grid-template-columns: minmax(0, .7fr) minmax(0, 1fr);
  }

  .advisory-member-card {
    .team-member-name {
      text-align: left;
      font-size: 2rem;
      padding-bottom: 1vh;
    }
  }

  .team-member-headline {
    text-align: left;
  }

  .team-member-card {
    .team-member-name {
      text-align: left;
    }
  }

  .meetTeamGrid {
    width: 95%;
  }

  .meetTeamGridLeadership {
    width: 95%
  }

  .responsiveGridTeam, .responsiveGridDev,.responsiveGridCore .responsiveGrid4 .gridFor3Items {
    grid-template-columns: 1fr !important;
    min-width: 380px;
  }
  .responsiveGridDev {
    grid-template-columns: 1fr;
  }
}

