.patentWidget {
  flex: 1;
  border: 1px solid rgb(192, 192, 192);
  border-radius: 15px;
  background-color: white;
  box-shadow: -0.4em 0.4em 0.4em gray;
  //   width: 35vw;
  max-width: 550px;
  min-width: 400px;
  margin: 3vw;
  margin-top: 2vh;
  margin-bottom: 2vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  padding-top: 1vh;
  padding-bottom: 2.5vh;
  position: relative;
  cursor: pointer;
  height: 35vh;
  min-height: 580px;
}

.patentWidget:hover,
.patentWidget:active {
  filter: brightness(95%);
}

.widgetHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.patentPicture {
  max-width: 400px;
  max-height: 300px;
  width: 95%;
}

.picturePlaceholder {
  max-width: 400px;
  max-height: 300px;
  width: 95%;
  box-sizing: inherit;
}