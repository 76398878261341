@import "animations";
@import "colors";
@import "fonts";

html,
body {
  width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit !important;
  /* blue colors for links too */
  text-decoration: none !important;
  /* no underline */
}

.logo:hover {
  filter: brightness(80%);
  cursor: pointer;
}

svg {
  position: none;
  left: 0;
  top: 0;
  z-index: -1;
  font-family: Rubik !important;
}

.rubik {
  font-family: Rubik !important;
}

.nonSelected > * {
  font-family: Rubik !important;
  color: white;
  text-decoration: none;
}

.colorWhite > * {
  font-family: Rubik !important;
  color: #ff9c33;
}

.landing {
  background-color: #fff;
  /* background-image:url(./res/background.gif); */

  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-attachment: fixed;
  margin-bottom: 5em;
}

.headline {
  color: #222;
  font-size: 25px;
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  white-space: normal;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em;
  /* Adjust as needed */
}
.my-btn {
  font-size: 30px;
  /* width:90%; */
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 1em;

  border: 2px solid #aaa;
  color: #222;
}

.btn {
  font-size: 30px;
  /* width:90%; */
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 1em;

  border: 2px solid #aaa;
  color: #222;
}

// .btn:hover {
//   animation:changeBorder ease .3s;
//   color:white;;
//   background-color:#3f51b5;
//   border:2px solid #fff;
// }

.btn-home:hover {
  /* animation:changeBorder ease .3s; */
  transition: background-color 0.4s;
  transition: color 0.4s, background-color 0.4s;
  color: white;
  background-color: #12afe1;
  /* border:2px solid #fff; */
  transition: background-color 0.4s;
  transition: color 0.4s, background-color 0.4s;
}

.btn-model {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-model:hover {
  color: #212529;
  text-decoration: none;
}

.btn-model {
  width: 20em;
  padding-top: 1.5em;
  padding-bottom: 2em;
  background-color: #12afe1;
  color: #fff;
  /* border-color:*/
}

.btn-model:hover {
  color: #d5d5d5 !important;
  background-color: #12afe1 !important;
  border-color: #1d3175 !important;
}

.main {
  display: flex;
}

.selectedListItem {
  border-bottom: 1px solid black;
  /* color:#3f51b5; */
}

.card {
  background-color: #eeeeee;
  padding: 1.7em;
}

blockquote {
  margin: 0;
}

.cardSpan {
  width: 70px;
  height: 70px;
  display: block;
  background: gray;
  border-radius: 50%;
  margin: 0 auto;
}

cite {
  padding-left: 20px;
  padding-top: 15px;
  font-size: 1.2em;
  font-style: normal;
  color: white;
  font-family: RubikRegular;
}

.moleculeImage {
  position: relative;
  z-index: 2;
}

.moleculeImage::after {
  z-index: -1;

  content: "";
  width: 80%;
  margin-left: 20%;
  margin-top: -50px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  filter: blur(8px);

  display: block;
}

.darkHover:hover {
  background-color: #eee;
}

/* */

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.form-submit-button {
  border-radius: 20px;
  font-size: 1.3em;
  color: white;
  background-image: linear-gradient($blue, #0982c6);
  text-align: center;
  padding-top: 0.2em;
  padding-bottom: 0.2em;

  cursor: pointer;
  /* text-transform:uppercase; */
}

.form-submit-button:hover {
  filter: brightness(80%);
}

.form-submit-button:active {
  filter: brightness(65%);
}

.form-submit-button-loading {
  border-radius: 20px;
  font-size: 1.3em;
  color: white;
  background-image: linear-gradient(#003099, #0762a6);
  text-align: center;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 1em;
  padding-right: 1em;
  cursor: pointer;
  /* text-transform:uppercase; */
}

.btn-models {
  border-radius: 200px;
  background-color: white;
  -webkit-box-shadow: 10px 9px 23px -8px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 10px 9px 23px -8px rgba(0, 0, 0, 0.42);
  box-shadow: 10px 9px 23px -8px rgba(0, 0, 0, 0.42);
  text-align: center;
  /* text-transform:uppercase; */
}

.btn-models-text {
  font-family: RubikMedium;
  font-size: 1.2em;
  padding: 0.5em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;

  background-image: $gradient2-45deg;

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.btn-models:hover,
.btn-models:active {
  filter: brightness(80%);
  cursor: pointer;
}

.btn-blob {
  /* padding-top:3em;
   padding-bottom:3em; */

  /* background:#333; */
  border-radius: 200px;
  border: 2px solid #12afe1;
  color: #12afe1;
  text-align: center;

  padding-left: 50px;
  padding-right: 50px;

  margin: 20px 20px;
  cursor: pointer;
}

.btn-blob.left:hover:after {
  border-width: 3px;
  animation: nW_aH 0.5s;
  height: 100%;
}

.btn-blob.right:hover:before {
  border-width: 3px;
  animation: nW_aH 0.5s;
  height: 100%;
}

.hoverBlue:hover {
  color: #3f51b5;
}

.buttonGrid {
  display: flex;
  flex-wrap: wrap;
}

.landingpageContainer {
  align-items: center;
  display: flex;
}

.desktopFlex {
  display: flex;
}

.tabletFlex {
  display: flex;
}

.footerPhrase{
    color: white;
    font-size: 25px;
    margin-bottom: 0.3em;
    line-height: 35px;
}

.desktopFooterOptions{
  display: flex;
}

@media(max-width: 1150px) {
  .landingpageContainer {
    margin: auto;
    flex-direction: column;
  }

  .contactInput {
    width: 95%;
  }

  .desktopFlex {
    flex-direction: column;
    width: 80%;
    margin: auto;
  }

  .moleculeImage {
    width: 60%;
  }

  .footerRight{
    display: flex;
  }

  .footerPhrase{
    color: white;
    font-size: 20px;
    margin-bottom: 0.3em;
    width: 100%;
  }
}

.inner {
  /* height:100vh; */
  width: 100vw;
  /* overflow:hidden; */
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: $gradient1-90deg-rev;
}

.responsiveGrid {
  display: grid;
  grid-gap: 2em;
  width: 70%;
  margin: auto;
}

.responsiveGridFulLWidth {
  display: grid;
  column-gap: 2em;
  row-gap:0em;
  margin: auto;
}
.responsiveGridTablet {
  display: grid;
  grid-gap: 2em;
  width: 75%;
  margin: auto;
}

.responsiveGrid2 {
  grid-template-columns: 1fr 1fr;
}

.responsiveGrid3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.responsiveGrid4 {
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
}

.responsiveGrid5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
}

@media (min-width: 502px) {
  .desktop30LPadding {
    padding-left: 30%;
  }
}

@media(min-width: 1151px) {
  .quoteFont {
    font-size: 30px;
  }

  .deNovoHeader {
    height: 100vh;
  }

  .desktopPaddingNews {
    padding-top: 0.7em;
  }
}

@media(max-width: 1150px) {
  .quoteFont {
    font-size: 25px;
  }

  .moleculeImage {
    margin-top: 30px;
  }

  .mobileHowWeWork {
    width: 50%;
    margin: auto;
  }

  .responsiveGrid {
    grid-template-columns: 1fr;
  }

  .footerLeft {
    padding-left: 2em;
  }
}

@media (max-width: 700px) {
  .closeGrid700 {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 500px) {
  .responsiveGridTablet {
    grid-template-columns: 1fr;
  }

  .tabletFlex {
    flex-direction: column;
    width: 80%;
    margin: auto;
  }
  .quoteFont {
    font-size: 20px;
  }
}

.polygon {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.1;
  -webkit-clip-path: polygon(8% 0, 100% 0, 100% 78%, 79% 100%, 0 65%);
  clip-path: polygon(8% 0, 100% 0, 100% 78%, 79% 100%, 0 65%);
  /* border-radius:100px; */
  width: 35vw;
  height: 35vw;
  background: #e0e6e5;
}

.polygon2 {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.1;
  -webkit-clip-path: polygon(89% 0, 100% 19%, 84% 60%, 20% 61%, 0 23%, 16% 0);
  clip-path: polygon(89% 0, 100% 19%, 84% 60%, 20% 61%, 0 23%, 16% 0);
  /* border-radius:100px; */
  width: 35vw;
  height: 35vw;
  background: #e0e6e5;
}

.categoryHeader {
  color: #12afe1;
  font-family: RubikRegular;
  font-size: 1.5em;
  padding-bottom:20px;
}

.whatWeDoFlex {
  flex: 7;
}
@media (max-width: 1024px) {
  .whatWeDoFlex {
    flex: 9;
  }
}
.dislogHolderAboutRo5 {
  flex: 8;
  margin-bottom: 2vh;
}
.dialogBox {
  /* clip-path:inset(9%, 10% round 40px, 20% ,15% ,10%); */
  border-radius: 40px 0px 80px 0px;
  background-image: $gradient3-45deg;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 5%;
  padding-right: 5%;
  /* height:100px; */
}

.dialogText {
  color: white;
  font-size: 2em;
}

.dialogDate {
  font-size: 20px;
  font-family: rubikRegular;
}

.benefitsDialogBox {
  /* clip-path:inset(9%, 10% round 40px, 20% ,15% ,10%); */
  border-radius: 40px 0px 80px 0px;
  background-image: $gradient2-45deg-rev;
  padding-top: 60px;
  padding-left: 50px;
  padding-right: 15px;
  margin-bottom: 30px;
  /* height:100px; */
}

.benefitsDialogText {
  color: white;
  font-family: rubikRegular;
}

.whatWeDo {
  position: absolute;
  z-index: -1;
  opacity: 0.08;
  width: 50vw;
  height: 50vh;
  background-image: url("../res/assets/Background/Artboard – 2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  top: -10%;
  right: -6%;
}

.blueBg {
  background-image: $gradient2-135deg;
}

.categoryTitle {
  color: $blue;
  font-size: 2.1em;
  line-height: 1.2em;
}

.howItWorksTitle {
  color: $blue;
  font-size: 1.4em;
  margin-bottom: 12px;
}
.platformButton {
  // height:250px;
}
.platformImage:hover {
  cursor: pointer;
  filter: brightness(95%);
}
.platformImage {
  max-width: 145px;
}
@media (max-width: 900px) {
  .platformImage {
    max-width: 150px;
  }
}
@media (max-width: 500px) {
  .platformImage {
    max-width: 100px;
  }
}
.primaryColor {
  color: $blue;
}

.fa {
  font-family: FontAwesome !important;
}

.historyHeader {
  font-size: 3em;
  margin-bottom: 3em;
  color: #444;
  padding-top: 120px;
  padding-bottom: 70px;
  background-image: $gradient2-110deg-rev;
  color: white;
  font-family: RubikMedium;
}

.deNovoHeader {
  font-size: 3em;
  color: #444;

  background-image: $gradient4-45deg-rev;
  margin-bottom: 3em;
  color: white;
  font-family: RubikMedium;
}

/* .deNovoHeader::after{
  content:"";
  background-image:url('./res/assets/Icons/Ro5_Modules/De_Novo\ –\ 1.svg');
  background-size:cover;
  background-blend-mode:lighten;
  filter:invert(100%);
  opacity:.1;


  height:200vh;
  width:100vw;
  position:absolute;

  z-index:10; 
} */

.newsHeader {
  font-size: 3em;
  margin-bottom: 3em;
  color: #444;
  background-color: $news-bg;
  color: white;
  font-family: RubikMedium;
  height: 100vh;
  width: 100%;
}

.paperLink {
  padding-left: 0.5em;
  font-family: RubikRegular;
  font-size: 1.2em;
  background-image: $gradient2-45deg;
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.gradient {
  padding-left: 0.5em;
  font-size: 1.2em;
  background-image: $gradient2-45deg;
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.pointer:hover {
  cursor: pointer;
}

.paperLink:hover {
  filter: brightness(80%);
  background-image: linear-gradient(45deg, #4197c3, #003079);
}

.hoverDark:hover {
  filter: brightness(80%);
}

.headerText:hover .caret {
  transform: rotate(-180deg);
  /* Equal to rotateZ(45deg) */
  transition: transform 0.5s linear;
  padding-top: 2px;
  padding-bottom: initial;
}

.caret {
  vertical-align: middle;
  padding-bottom: 2px;
  transition: transform 0.5s linear;
}

.footer {
  display: grid;
  grid-template-columns: 45% 4% 33% 33%;
  grid-gap: 2em;
  background-image: $footer-gradient;
  padding-top: 2em;
  // margin-top: 8em;
  width: 100vw;
  overflow: hidden;
}

.footerLinks > * {
  color: white;
  font-family: RubikRegular;
}

.footerLink {
  padding-top: 0.2em;
  font-size: 20px;
  cursor: pointer;
}

.footerLinkUndr {
  padding-top: 0.2em;
  font-size: 20px;
  cursor: pointer;
}

.footerLinkUndr:hover {
  text-decoration: underline;
}

.footerLinkNoPointer {
  padding-top: 0.2em;
  font-size: 20px;
}

.footerLinkDropdownUndr {
  padding-top: 0.2em;
  font-size: 20px;
}

.historyComponentList {
  width: 45%;
  margin: auto;
}

.mobileFooterAddress{
  display: none;
}

@media(max-width: 1150px) {
  .pageHeaderText {
    width: 90%;
  }

  .deNovoHeader {
    margin-bottom: 1em;
  }

  .mobileFooterAddress{
    display: block;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  .historyComponentList {
    width: 70%;
  }

  .newsHeader {
    height: 100%;
  }

  .custom-select {
    margin-bottom: 2.2em;
  }

  .desktop {
    width: 80vw;
    display: flex;
    justify-content: flex-end;
  }

  .footer {
    display: grid;
    grid-template-columns: 1fr;
    background-image: $footer-gradient;
    padding-top: 2em;
    width: 100vw;
    overflow: hidden;
  }

  .newsFlex {
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
  }

  .deNovoFlex {
    flex-direction: column;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 50px;
  }

  .mobile90W {
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media(min-width: 1151px) {
  .pageHeaderText {
    width: 50%;
  }

  .mobile,
  .tablet {
    display: none !important;
  }

  .custom-select {
    max-width: 47%;
  }

  .footerLeft {
    padding-left: 4em;
  }

  .mobileKGSlider {
    display: none;
  }

  .bioactivityMobileSlider {
    display: none;
  }

  .footerRight{
    color: white;
    font-size: 22px;
  }

  .footer-container{
    width: 150vw;
    .footerLeft{
      img{
        padding-bottom: 1vh;
      }
    }
  }
}

@media (max-width: 500px) {
  // Hide tablet
  .tablet {
    display: none;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: initial;
  }

  .desktopKnowledgeGraphPictures {
    display: none;
  }

  .bioactivityDesktopSlider {
    display: none;
  }

  .kgMobileWidget{
    margin-bottom: 3vh;
    margin-top: 2vh;
  }

  .footerRightMobile{
    font-size: 18px;
    color: white;
  }

  .copyright{
    font-size: 16px;
  }

  .ro5LogoFooter{
    width: 30%;
    max-width: 300px;
    padding-bottom: 1vh;
  }
  
  .desktopFooterOptions{
    display: none;
  }
}

@media (min-width: 500px) and (max-width: 1150px) {
  // Show tablet
  .desktop,
  .mobile {
    display: none;
    width: auto;
  }

  .footerRight{
    display: none;
  }

  .footerRightMobile{
    font-size: 18px;
    color: white;
  }

  .copyright{
    font-size: 16px;
  }

  .ro5LogoFooter{
    width: 50%;
    max-width: 300px;
    padding-bottom: 1vh;
  }

  .desktopFooterOptions{
    display: flex;
  }

  .desktopKnowledgeGraphPictures {
    display: none;
  }

  .kgMobileWidget{
    margin-bottom: 3vh;
    margin-top: 2vh;
  }

  .bioactivityDesktopSlider {
    display: none;
  }

  .responsiveGrid4 {
    margin: 0;
    grid-template-columns: repeat(4, 25%) !important;
  }

  .responsiveGrid5 {
    margin: 0;
    grid-template-columns: repeat(5, 20%) !important;
  }
}

.newsFlex {
  display: flex;
}

.deNovoFlex {
  display: flex;
}

.desktopImage {
  padding-top: 2em;
  margin: auto;
  width: 50%;
}

*:focus {
  outline: 0;
  outline: none;
}
.macbookAir {
  // height:119%;
  width: 127%;
  // height:auto;
}
