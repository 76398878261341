@import "colors";

@font-face {
  font-family: Rubik;
  src: url(../res/font/Rubik/Rubik-Light.ttf) format("opentype");
}

@font-face {
  font-family: RubikRegular;
  src: url(../res/font/Rubik/Rubik-Regular.ttf) format("opentype");
}

@font-face {
  font-family: RubikMedium;
  src: url(../res/font/Rubik/Rubik-Medium.ttf) format("opentype");
}

@font-face {
  font-family: RubikBold;
  src: url(../res/font/Rubik/Rubik-Bold.ttf) format("opentype");
}

@font-face {
  font-family: Rubik-Bold;
  src: url(../res/font/Rubik/Rubik-Bold.ttf) format("opentype");
}

.servicesWhiteBg {
  width: 70%;
  font-size: 1.6rem;
}

.servicesHolder {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1.6rem;
}

.servicesHolderWithWidget {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-items: center;
  align-items: center;
}

.listToWidget {
  grid-column: span 1;
}

.widgetServices {
  border-radius: 40px 0px 80px 0px;
  padding-top: 2.35vw;
  padding-left: 1.95vw;
  padding-right: 1.95vw;
  margin-bottom: 1.4vw;
  background-color: white;
  color: black;
  max-width: 25vw;
  display: block;
  overflow: auto;
  height: auto;
}

.blueWidgetServices {
  border-radius: 40px 0px 80px 0px;
  background-image: $gradient2-45deg-rev;
  padding-top: 2.35vw;
  padding-left: 1.95vw;
  padding-right: 1.95vw;
  margin-bottom: 1.4vw;
  color: white;
  min-width: 350px;
  display: block;
  overflow: auto;
  height: auto;
}

.widgetListNameHolder{
  display: inline;
  padding-top: 0.5vh;
  padding-right: 1vw;
}

.whiteWidgetName{
  color: #0050a9;
  font-size: 31.5px;
  font-weight: 600;
  padding-bottom: 1vh;
}

.blueWidgetName{
  font-size: 31.5px;
  font-weight: 600;
  padding-bottom: 1vh;
}

.sectionTitle {
  color: $blue;
  font-size: 4rem;
}

.serviceTitle {
  color: $blue;
  width: 75%;
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 2vh;
  font-size: 3.5rem;
  font-weight: 600;
}

.listStyle {
  margin-top: 1vh;
  margin-left: 1.5rem;
}

.listItemStyle {
  margin-bottom: 0.3vh;
}

.paraTitle {
  font-size: 42px;
  color: #0050a9;
  margin-bottom: 10px;
}

.paraTitleWhite {
  font-size: 31.5px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.paraContent {
  font-size: 22.5px;
  color: #434343;
  margin-bottom: 20px;
}

.paraContentWhite {
  font-size: 22.5px;
  color: #FFFFFF;
  margin-bottom: 20px;
  font-weight: 300;
}

.whiteBgIntroText {
  font-size: 31.5px;
  color: #0050a9;
  margin-bottom: 25px;
}

.blueBgIntroText {
  font-size: 31.5px;
  color: #FFFFFF;
  margin-bottom: 25px;
}

.whiteBgFlex {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
  margin-bottom: 8vh;
}

.whiteBgFlexMobile {
  display: none;
}

.whiteBgFlexLanding {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  margin-bottom: 8vh;
  align-items: center;
}

.widgetList{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-300) var(--unnamed-font-size-25)/var(--unnamed-line-spacing-35) var(--unnamed-font-family-rubik);
  font-size: 38px;
  line-height: 31.5px;
}

.widgetListItem{
  margin-bottom: 1vh;
  padding-left: 0.65vw;
}

.widgetListItemEntry{
  font-size: 22.5px;
}

.widgetListItemEntry2{
  font-size: 18px;
}

.serviceSectionTitleLanding{
  font-size: 75px;
}

.serviceSectionTitle{
  font-size: 75px;
  margin-top: 2vh;
}

.servicesStory{
  width: 60%;
  min-width: 250;
  padding-top: 0.3em;
  margin: auto;
  font-size: 27px;
  font-family: RubikRegular;
}

.featureComponent{
  display: flex;
  padding-bottom: 2rem;
  margin-left: 2vw;
  margin-right: 2vw;
  cursor: pointer;
}

.FC-Title{
  color: $blue;
  font-size: 1.4em;
  margin-bottom: 12px;
  font-size: 27px;
  text-decoration: underline;
}

.FC-TitleHolder{
  display: flex;
  align-items: center;
  width: 250px;
}

.blueBgSectionName{
  font-family: Rubik-Bold;
  font-size: 42.75px;
  margin-bottom: 3vh;
  color: #FFFFFF;
}

.serviceIntro{
  color: #0050a9;
  line-height: 1.2em;
  width: 65%;
  margin: auto;
  padding-bottom: 1.5em;
  font-size: 27px;
  text-align: center;
}

.allServicesList{
  display: flex;
  flex-direction: column;
}

.blueBgBottomList{
  width: 60%;
  font-size: 22.5px;
  color: #FFFFFF;
  margin-bottom: 20px;
  font-weight: 300;
  text-align: left;
}

.blueBgFlex{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  margin-bottom: 3vh;
  align-items: center;
}

.whiteBgTitle{
  font-family: RubikBold;
  font-size: 42.75px;
  margin-bottom: 3vh;
  color: #0050a9;
}

.smallPageSection{
  font-family: Rubik;
  margin-top: 8vh;
}

.featureComponentList{
  display: flex;
  margin-top: 2vh;
  z-index: 10;
}

.mobileServicesWidget{
 display: none;   
}

@media(max-width: 1150px) {
  .serviceSectionTitleLanding{
    font-size: 55px;
  }
  .serviceSectionTitle{
    font-size: 55px;
    margin-top: 2vh;
  }
  .servicesStory{
    width: 80%;
    padding-top: 0.3em;
    margin: auto;
    font-size: 20px;
    font-family: RubikRegular;
  }
  .whiteBgTitle{
    font-family: RubikBold;
    font-size: 35px;
    margin-bottom: 3vh;
    color: #0050a9;
  }
  .whiteBgIntroText {
    font-size: 25px;
    color: #0050a9;
    margin-bottom: 25px;
  }
  .blueBgIntroText {
    font-size: 25px;
    color: #FFFFFF;
    margin-bottom: 25px;
  }
  .featureComponentList{
    display: flex;
    margin-top: 2vh;
    flex-direction: column;
    z-index: 10;
  }
  .whiteBgFlexMobile {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    margin-bottom: 8vh;
  }
  .whiteBgFlex {
    display: none;
  }
  .mobileServicesWidget{
    display: flex;
    margin-top: 3.5vh;
  }

  .blueWidgetServices {
    border-radius: 40px 0px 80px 0px;
    background-image: $gradient2-45deg-rev;
    padding-top: 2.35vw;
    padding-left: 3.5vw;
    padding-right: 1.95vw;
    margin-bottom: 1.4vw;
  
    color: white;
    min-width: 250px;
    display: block;
    overflow: auto;
    height: auto;
  }
}