@import "colors";

@font-face {
  font-family: Rubik;
  src: url(../res/font/Rubik/Rubik-Light.ttf) format("opentype");
}

@font-face {
  font-family: RubikRegular;
  src: url(../res/font/Rubik/Rubik-Regular.ttf) format("opentype");
}

@font-face {
  font-family: RubikMedium;
  src: url(../res/font/Rubik/Rubik-Medium.ttf) format("opentype");
}

@font-face {
  font-family: RubikBold;
  src: url(../res/font/Rubik/Rubik-Bold.ttf) format("opentype");
}

@font-face {
  font-family: Rubik-Bold;
  src: url(../res/font/Rubik/Rubik-Bold.ttf) format("opentype");
}

.primary-text {
  color: $blue;
}

.bright-blue-text {
  color: $blue-bright;
}
p{
  font-size:25px; 
  line-height:1.2em;
}
// @media (max-height:1024px){
//   p, ul {
//     font-size:20px;
//     line-height:1.2em;

//   }
// }

.bold {
  font-family: Rubik-Bold !important;
}

.regular {
  font-family: RubikRegular !important;
}

.medium {
  font-family: RubikMedium !important;
}

.light {
  font-family: Rubik !important;
}

html, body {
  font-family: Rubik !important;
}

a, .link {
  font-family: Rubik !important;

}

.category {
  font-family: Rubik !important;
  text-transform: uppercase;
}

@media(min-width: 1151px) {
  h1 {
    font-size: 3.9vw !important;
    line-height: 1 !important;
  }
  
  h2 {
    font-size: 2.3vw !important;
    line-height: 1.2 !important;
  }
  
  h3 {
    font-size: 1.8vw !important;
    line-height: 1.2 !important;
  }
  
  h4 {
    font-size: 1.6vw !important;
    line-height: 1.2 !important;
  }
  
  body {
    font-size: 1.3vw !important;
    line-height: 1.2 !important;
  }
}

@media(max-width: 1150px) {
  h1 {
    font-size: 37px !important;
    line-height: 1 !important;
  }

  h2 {
    font-size: 37px !important;
    line-height: 1 !important;
  }

  h3 {
    font-size: 25px !important;
    line-height: 1.2 !important;
  }

  h4 {
    font-size: 25px !important;
    line-height: 1.2 !important;
  }

  body {
    font-size: 20px !important;
    line-height: 1.2 !important;
  }
}